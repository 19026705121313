<template>
  <div>
    <div>
      <b-form
        v-if="items"
        @submit.prevent="repeateAgain"
        id="form"
        class="margin-form"
        ref="form"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col
            md="1"
          >
          </b-col>
          <!-- NUM -->
          <b-col
            md="1"
          >
            <b-form-group
              label="#"
              :label-for="'numero-'+item.id"
              class="text-center"
            >
              <b-form-input
                :id="'numero-'+item.id"
                v-model="item.id"
                class="text-center"
                type="number"
                plaintext
              />
            </b-form-group>
          </b-col>

          <!-- Item Name -->
          <b-col md="4">
            <b-form-group
              label="Producto"
              :label-for="'producto-'+item.id"
            >
              <v-select
                :id="'producto-'+item.id"
                v-model="item.idProducto"
                placeholder="Seleccione un producto"
                input-id="idProducto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="getProductos"
                :reduce="option => option.id"
                :getOptionLabel="option => option.codigoNombre"
                :selectable="option => !option.disabled"
                deselect="false"
                @input="seleccionaProducto(item)"
              >

                <template
                  disabled
                  #option="{ codigo, nombre, imagen }"
                >
                  <b-avatar
                    size="32"
                    :src="imagen"
                    :text="avatarText(nombre)"
                    class="mr-1"
                  />
                  <span> {{ codigo }} - {{ nombre }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Cantidad -->
          <b-col md="1">
            <b-form-group
              label="Cantidad"
              :label-for="'cantidad-'+item.id"
            >
              <cleave
                :id="'cantidad-'+item.id"
                v-model.number="item.cantidad"
                class="form-control"
                :raw="false"
                @input="item.cantidad = soloNumeros(item.cantidad), setValorUnitarioProducto(item)"
                :options="options.number"
                :disabled="item.idProducto == null"
              />
            </b-form-group>
          </b-col>

          <!-- stock -->
          <b-col md="1">
            <b-form-group
              label="Stock"
              :label-for="'stock-'+item.id"
            >
              <p class="card-text mt-1">
                {{ formatoMiles(item.stock) }}
              </p>
            </b-form-group>
          </b-col>

          <!-- Valor Unitario -->
          <b-col
            lg="1"
            md="1"
          >
            <b-form-group
              label="Valor unitario"
              :label-for="'valorUnitario-'+item.id"
            >
              <p class="card-text mt-1">
                {{ formatoPeso(item.valorUnitario) }}
              </p>
            </b-form-group>
          </b-col>

          <!-- Valor Total -->
          <b-col
            lg="1"
            md="1"
          >
            <b-form-group
              label="Valor total"
              :label-for="'valorTotal-'+item.id"
            >
              <p class="card-text text-primary mt-1">
                {{ formatoPeso(item.valorTotal) }}
              </p>
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col
            lg="1"
            md="1"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2 mb-sm-2 btn-sm-block"
              size="sm"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span></span>
            </b-button>
          </b-col>
          <colLinea
            class="margin-hr"
            ancho="xs"
          />
        </b-row>
      </b-form>
    </div>
    <b-row>
      <b-col lg="1">
      </b-col>
      <b-col lg="5">
        <b-button
          class="mt-2 ml-md-2 btn-sm-block"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span></span>
        </b-button>
      </b-col>
      <b-col>
        <b-form-group
          label="Total Salida"
          label-for="valorTotal"
          label-cols-md="6"
          class="text-right mt-2 h5"
        >
          <p class="h5 text-primary text-md-left text-sm-right margin-p">
            {{ formatoPeso(valorTotalSalida) }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BAvatar,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { formatos } from '@/mixins/forms'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'

import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

const colLinea = () => import('@/layouts/components/Recycled/Form/colLinea.vue')

export default {
  components: {
    vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAvatar,
    Cleave,

    colLinea,
  },
  props: {
    salidasProductos: {
      type: Array,
      required: true,
    },
    valorTotal: {
      type: Number,
      required: true,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition, formatos],
  data() {
    return {
      items: [],
      valorTotalSalida: 0,
      stockProporcional: [],
      productosOption: [],
      nextTodoId: 1,
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getProductos: 'productos/getProductos',
    }),
  },
  watch: {
    items() {
      this.$emit('update:salidasProductos', this.items)
      this.setProductoDisabled()
    },
    getProductos() {
      this.setItemsId()
      this.setProductoDisabled()
      this.setStockUpdate()
    },
  },
  mounted() {
    this.initTrHeight()
    this.items = this.salidasProductos
    this.setValorTotal()
  },
  created() {
    this.fetchProductos()
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      fetchProductos: 'productos/fetchProductos',
    }),
    getItemIndex(id) {
      return Number(id) - Number(1)
    },
    setStockUpdate() {
      this.items.forEach((item, i) => {
        if (item.idProducto !== null) {
          const producto = this.getProducto(item.idProducto)
          if (typeof producto !== 'undefined') {
            this.items[i].stock = Number(producto.stock)
            this.items[i].disabled = true
            this.stockProporcional.push({
              idProducto: item.idProducto,
              stock: Number(producto.stock) + Number(item.cantidad),
              cantidad: item.cantidad,
            })
          }
        }
      })
    },
    seleccionaProducto(item) {
      if (item.idProducto !== null) {
        this.setProductoDisabled()
        this.setCantidad(item, 0)
        this.setStock(item)
        this.setValorUnitarioProducto(item)
      } else {
        this.setProductoDisabled()
        this.setCantidad(item, 0)
        this.setStock(item, 0)
        this.setValorUnitarioProducto(item, 0)
        this.setValorTotalProducto(item, 0)
      }
    },
    getProducto(id) {
      const productoFind = this.getProductos.find(producto => producto.id === Number(id))
      return productoFind
    },
    setProductoDisabled() {
      this.getProductos.forEach(getProducto => {
        const findGetProductoEach = this.getProducto(getProducto.id)
        findGetProductoEach.disabled = false
      })
      this.items.forEach((itemEach, i) => {
        if (itemEach.idProducto !== null) {
          const findProductoEach = this.getProducto(itemEach.idProducto)
          if (typeof findProductoEach !== 'undefined') {
            findProductoEach.disabled = true
          }
          this.items[i].nuevo = true
        }
      })
    },
    setCantidad(item, cantidad) {
      const index = this.getItemIndex(item.id)
      this.items[index].cantidad = cantidad
    },
    getStocksUpdate() {
      this.items.forEach(item => {
        const stock = this.getStock(item)
        this.setStock(item, stock)
      })
    },
    getStockUpdate(item) {
      const findProducto = this.getProducto(item.idProducto)
      const stockProporcional = this.stockProporcional.find(
        stock => stock.idProducto === item.idProducto,
      )
      const cantidad = Number(item.cantidad)
      let stock
      if (typeof stockProporcional !== 'undefined') {
        if (cantidad < stockProporcional.cantidad) {
          if (cantidad === 0) {
            stock = stockProporcional.stock
          } else if (cantidad > 0) {
            stock = stockProporcional.stock - cantidad
          }
        } else if (cantidad > stockProporcional.cantidad) {
          stock = stockProporcional.stock - cantidad
        } else if (cantidad === stockProporcional.cantidad) {
          stock = Number(findProducto.stock)
        } else {
          stock = Number(findProducto.stock)
        }
        if (cantidad > stockProporcional.stock) {
          this.setCantidad(item, stockProporcional.stock)
          stock = 0
        }
      } else {
        stock = 0
      }

      return Number(stock)
    },
    getStock(item) {
      const findProducto = this.getProducto(item.idProducto)
      let stock
      if (typeof findProducto !== 'undefined') {
        if (item.cantidad <= findProducto.stock) {
          stock = findProducto.stock - item.cantidad
        } else if (item.cantidad > findProducto.stock) {
          this.setCantidad(item, findProducto.stock)
          stock = 0
        }
      } else {
        stock = 0
      }
      return stock
    },
    setStock(item, stock) {
      const index = this.getItemIndex(item.id)
      if (typeof stock !== 'undefined') {
        this.items[index].stock = stock
      } else {
        let getStock
        if (this.update && item.disabled === true) {
          getStock = this.getStockUpdate(item)
        } else {
          getStock = this.getStock(item)
        }
        this.items[index].stock = getStock
      }
    },
    getValorUnitarioProducto(item) {
      const findProducto = this.getProducto(item.idProducto)
      let valorUnitario = 0
      if (typeof findProducto !== 'undefined' && item.idProducto !== null) {
        findProducto.preciosCantidad.forEach(precioCantidad => {
          if ((Number(item.cantidad) === 0
            || Number(item.cantidad) === '0')
            && precioCantidad.opcionNeto === '1') {
            valorUnitario = Number(precioCantidad.precio)
          }
          if (Number(item.cantidad) >= precioCantidad.cantidad) {
            valorUnitario = Number(precioCantidad.precio)
          }
        })
      }

      return valorUnitario
    },
    setValorUnitarioProducto(item, valorUnitario) {
      if (item.id <= this.items.length) {
        const index = this.getItemIndex(item.id)
        if (typeof valorUnitario !== 'undefined') {
          this.items[index].valorUnitario = valorUnitario
        } else {
          const getValorUnitario = this.getValorUnitarioProducto(item)
          if (typeof this.items[index] !== 'undefined') {
            this.items[index].valorUnitario = Number(getValorUnitario)
            this.setStock(item)
            this.setValorTotalProducto(item)
          }
        }
      }
    },
    getValorTotalProducto(item) {
      let valorTotalProducto = 0
      if (item.cantidad < 0 || item.cantidad === 0) {
        valorTotalProducto = Number(0)
      } else if (item.cantidad > 0) {
        valorTotalProducto = Number(item.valorUnitario) * Number(item.cantidad)
      }
      return valorTotalProducto
    },
    setValorTotalProducto(item, valorTotalProducto) {
      const index = Number(item.id) - Number(1)
      if (typeof valorTotalProducto !== 'undefined') {
        this.items[index].valorTotal = valorTotalProducto
      } else {
        const getValorTotalProducto = this.getValorTotalProducto(item)
        this.items[index].valorTotal = getValorTotalProducto
      }
      this.setValorTotal()
    },
    getValorTotal() {
      let valorTotal = 0
      this.items.forEach(item => {
        valorTotal = Number(valorTotal) + Number(item.valorTotal)
      })
      this.valorTotalSalida = Number(valorTotal)
      return valorTotal
    },
    setValorTotal() {
      const valorTotal = this.getValorTotal()
      this.$emit('update:valorTotal', valorTotal)
    },
    // Vuexy
    setItemsId() {
      this.items.forEach((item, i) => {
        this.items[i].id = i + 1
        this.items[i].idProducto = Number(item.idProducto)
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += 1,
        idProducto: null,
        cantidad: 0,
        valorUnitario: 0,
        valorTotal: 0,
        stock: 0,
        disabled: false,
      })
      this.setItemsId()
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        const scrollAlFinal = document.getElementById('form')
        scrollAlFinal.scrollTop = '9999'
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.setItemsId()
      this.setValorTotal()
      this.setProductoDisabled()
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
// .margin-hr {
//   margin-top: -2px;
// }
@media (max-width:616px){
  .margin-hr {
    margin-top: 20px;
  }
}
@media (min-width:615px){
  .margin-hr {
    margin-top: -2px;
  }
}

.margin-form {
  margin-top: 8px;
}
.margin-p {
  margin-top: 10px;
  margin-left: 2%;
}
</style>
